<script setup lang="ts">
  import { Text } from '@bt/design-system'
  import type { StructuredFinancialReportsGroups } from '@/types/Storyblok/General/StructuredFinancialReport'

  const { t } = useI18n()

  const props = withDefaults(
    defineProps<{
      blok: StructuredFinancialReportsGroups
      isRounded?: boolean
    }>(),
    {
      isRounded: true
    }
  )

  const REPORT_TYPES = {
    annualReport: t('financial.report.annual'),
    nineMonthsReport: t('financial.report.nineMonths'),
    sixMonthsReport: t('financial.report.sixMonths'),
    threeMonthsReport: t('financial.report.threeMonths')
  }

  type ReportType = keyof typeof REPORT_TYPES

  const availableReports = computed(() => {
    const reportTypeKeys = Object.keys(REPORT_TYPES) as Array<ReportType>

    const availableReportKeys = reportTypeKeys.filter((key) => {
      const specificReportData = props.blok[key]

      return !!specificReportData?.[0]?.report
    }) as Array<ReportType>

    if (!availableReportKeys.length) {
      return []
    }

    return availableReportKeys.map((key) => ({
      key,
      title: REPORT_TYPES[key],
      data: props.blok[key]
    }))
  })
</script>

<template>
  <div
    v-if="availableReports.length"
    class="structured-financial-reports-groups"
    :class="{
      'rounded-lg': isRounded,
      'structured-financial-reports-groups__spacing': blok.hasBottomSpacing
    }"
  >
    <div
      v-for="report in availableReports"
      :key="report.key"
      class="structured-financial-reports-groups__item"
    >
      <Text
        type="subtitle1"
        :text="report.title"
        tag="p"
        class="structured-financial-reports-groups__title"
      />
      <StructuredFinancialReports
        v-if="report.data"
        :blok="report.data"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/assets/styles/variables.scss' as *;
  @use '@/assets/styles/breakpoints.scss' as *;
  @use '@/assets/styles/fonts.scss' as *;

  .structured-financial-reports-groups {
    background-color: $bg-grey;
    padding: rem(24) rem(16);

    &__spacing,
    &__title {
      margin-bottom: rem(24);
    }

    &__item {
      margin-bottom: rem(16);
      padding-bottom: rem(16);
      border-bottom: 1px solid $divider;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    @include desktop-l-up {
      padding: rem(24);

      &__spacing {
        margin-bottom: rem(32);
      }
    }
  }
</style>
